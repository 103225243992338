<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 my-lg-0">
      <div class="card-title">
        <div class="card-label font-weight-bolder">
          {{ $t('dashboard.bookings.title') }}
        </div>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body Active-->
    <div v-if="bookings_list.length > 0 || !loaded" class="card-body d-flex flex-column flex-grow pt-0">
      <!--begin::Items-->
        <!--begin::Widget Item-->
        <div v-if="loaded" class="card-body-content">
          <div class="d-flex align-items-center mb-5" v-for="item in bookings_list" :key="item.id">
            <!--begin::Symbol-->
            <div class="symbol symbol-40 flex-shrink-0 mr-3">
              <div class="symbol-label" :style="`background-image: url('${$photoUrl(item.photo, 100)}')`"></div>
            </div>
            <!--end::Symbol-->
            <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
              <div class="white-space-nowrap">
                <span class="text-dark font-weight-bolder mb-1 font-size-lg mr-2">{{ item.name }}</span>
                <font-awesome-icon icon="caret-right" class="text-muted mr-2"/>
                <router-link :to="`/spaces/${item.location_id}`" class="text-success font-weight-bolder">{{item.location_name}}</router-link>
              </div>
              <div v-if="locationList.length > 0" class="text-muted font-weight-bolder">{{ 1 | formatDateInterval(item.ts_start, item.ts_end, getTimezone(item.location_id)) }}</div>
            </div>
          </div>
        </div>
        <div v-if="!loaded" class="d-flex flex-nowrap align-items-center overflow-hidden mb-5">
          <!--begin::Symbol-->
          <div class="symbol symbol-40 flex-shrink-0 mr-3">
            <div class="symbol-label"><PuSkeleton/></div>
          </div>
          <!--end::Symbol-->
          <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
            <div class="white-space-nowrap">
              <span class="text-dark font-weight-bolder mb-1 font-size-lg mr-2"><PuSkeleton/></span>
              <font-awesome-icon icon="caret-right" class="text-muted mr-2"/>
              <span class="text-success font-weight-bolder"><PuSkeleton/></span>
            </div>
            <div class="text-muted font-weight-bolder"><PuSkeleton/></div>
          </div>
        </div>
        <!--end::Widget Item-->
      <!--end::Items-->
      <router-link v-if="loaded" to="/order/bookings" class="btn btn-light-success w-100 mt-auto">
        {{ $t('dashboard.bookings.all_reservations') }}
      </router-link>
    </div>
    <!--end::Body Active-->
    <!--begin::Body InActive-->
    <div v-else class="card-body d-flex flex-column pt-0">

      <!--begin::Short Info-->
      <p v-html="$t('dashboard.bookings.inactive')"></p>
      <div class="mt-auto">
        <router-link to="/services/rooms" class="btn btn-block btn-primary">
          {{ $t('dashboard.bookings.buy_meeting_room') }}
        </router-link>
        <router-link to="/order/bookings" class="btn btn-block btn-light-primary">
          {{ $t('dashboard.bookings.all_reservations') }}
        </router-link>
      </div>
      <!--end::Short Info-->
    </div>
    <!--end::Body InActive-->
  </div>
</template>

<script>
import {mapGetters, mapState, mapActions} from 'vuex'
import moment from 'moment-timezone'

export default {
  computed: {
    ...mapState({
      // packages: state => state.dashboard.packages,
    }),
    ...mapGetters({
      bookings: 'dashboard/bookings',
    }),
    ...mapGetters({
      locationList: 'locationOrder/list'
    })
  },
  data: () => {
    return {
      bookings_list: [],
      loaded: false,
    }
  },
  methods: {
    ...mapActions('dashboard', [
      'BookingsInit'
    ]),
    getTimezone(locationId) {
      const location = this.locationList.find(item => {
        return item.id === locationId;
      })
      return location && location.city ? location.city.timezone : 'UTC';
    }
  },
  watch: {
    bookings: {
      handler() {
        if (this.bookings.length > 0) {
          for (let i in this.bookings) {
            this.bookings_list.push({
              id: this.bookings[i].id,
              name: this.bookings[i].room.name,
              photo: this.bookings[i].room.photos.length > 0 ? this.bookings[i].room.photos[0] : null,
              location_name: this.bookings[i].location.name,
              location_id: this.bookings[i].location.id,
              ts_start: this.bookings[i].ts_start,
              ts_end: this.bookings[i].ts_end,
            })
          }
        }
        this.loaded = true
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
  .card-custom {
    height: 304px!important;
  }
  .card-body-content {
    overflow-y: scroll!important;
  }
  @media (max-width: 991px) {
    .card-custom {
      height: 343px!important;
    }
  }
</style>
